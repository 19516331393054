import activityService from "@/services/activityList";
import candidate from "@/services/candidate";
import { getFilterQueryStringWithoutArray, getFilterQueryString, Role } from "@/helpers/helper.js";
import router from "../router/index";

const getDefaultState = () => {
    return {
        activityList: [],
        isFetchingActivityList: false,
        selectedActivity: null,
        currentUserType: null,
        isShowUserTypeModal: false,
        activeTab: 0,
        activeSubTab: 0,
        activityePagination: {
            limit: 10,
            skip: 0,
            noMoreDataFromActivityList: false
        },
        reviewedJobID: [],
        authoriserActions: [],
        jobTransactions: [],
        jobDisplayStatus: [],
        comments: [],
        jobApprovalProcessCounts: {
            "job_authorizer": {
                "pending": 0,
                "completed": 0,
                "expired": 0
            }
        },
        jobCandidateProcessCounts: {
            "reviewer": {
                "pending": 0,
                "completed": 0,
                "expired": 0
            },
            "interviewer": {
                "schedule": {
                    "pending": 0,
                    "completed": 0,
                    "expired": 0
                },
                "interview": {
                    "pending": 0,
                    "completed": 0,
                    "expired": 0
                }
            }
        },
        reviewedCandidateID: [],
        interviewSlots: {},
        interviewComments: null,
        actionDetailByID: {},
        interviewFeedBack: [],
        isFetchingActivityTransactions: false,
    };
}
const state = getDefaultState();

const getters = {
    activityList: state => state.activityList,
    isFetchingActivityList: state => state.isFetchingActivityList,
    selectedActivity: state => state.selectedActivity,
    currentUserType: state => state.currentUserType,
    isShowUserTypeModal: state => state.isShowUserTypeModal,
    activeTab: state => state.activeTab,
    activeSubTab: state => state.activeSubTab,
    noMoreDataFromActivityList: (state, getters, rootState) =>
        state.activityePagination.noMoreDataFromActivityList,
    getActivityPaginationSkip: (state, getters, rootState) => state.activityePagination.skip,
    getActivityPaginationLimit: (state, getters, rootState) =>
        state.activityePagination.limit,
    getReviewedJobID: state => state.reviewedJobID,
    getAuthoriserActions: state => state.authoriserActions.map(v => ({ code: v.status_id, label: v.display_text })) || [],
    getJobDisplayStatus: state => state.jobDisplayStatus.map(v => ({ code: v.status_id, label: v.status })) || [],
    getJobTransactions: state => state.jobTransactions,
    getComments: state => state.comments,
    getJobApprovalProcessCounts: state => state.jobApprovalProcessCounts,
    getJobCandidateProcessCounts: state => state.jobCandidateProcessCounts,
    getReviewedCandidateID: state => state.reviewedCandidateID,
    getInterviewCreatedSlots: state => state.interviewSlots,
    getInterviewComments: state => state.interviewComments,
    actionDetailByID: state => state.actionDetailByID,
    interviewFeedBack: state => state.interviewFeedBack,
    isFetchingActivityTransactions: state => state.isFetchingActivityTransactions,
}

const mutations = {
    ['SET_ACTIVITY_LIST'](state, payload) {
        state.activityList = payload;
    },
    ['SET_IS_FETCHING_ACTIVITY_LIST'](state, data) {
        state.isFetchingActivityList = data;
    },
    ['SET_SELECTED_ACTIVITY'](state, payload) {
        state.selectedActivity = payload;
    },
    ['RESET_SELECTED_ACTIVITY'](state) {
        state.selectedActivity = null;
    },
    ['SET_CURRENT_USER_TYPE'](state, data) {
        state.currentUserType = data;
    },
    ['RESET_CURRENT_USER_TYPE'](state) {
        state.currentUserType = null;
    },
    ['SET_IS_SHOW_USER_TYPE_MODAL'](state, data) {
        state.isShowUserTypeModal = data;
    },
    ['SET_ACTIVE_TAB'](state, data) {
        state.activeTab = data;
    },
    ['SET_ACTIVE_SUB_TAB'](state, data) {
        state.activeSubTab = data;
    },
    ['SET_ACTIVITY_LIST_PAGINATION'](state, data) {
        state.activityePagination = { ...state.activityePagination, ...data }
    },
    ['SET_REVIEWED_JOBID'](state, data) {
        state.reviewedJobID = [...state.reviewedJobID, data]
    },
    ['SET_REVIEWED_CANDIDATEID'](state, data) {
        state.reviewedCandidateID = [...state.reviewedCandidateID, data]
    },
    ['SET_AUTHORISER_ACTIONS'](state, data) {
        state.authoriserActions = data;
    },
    ['SET_JOB_TRANSACTIONS'](state, data) {
        state.jobTransactions = data;
    },
    ['RESET_JOB_TRANSACTIONS'](state) {
        state.jobTransactions = [];
    },
    ['SET_JOB_DISPLAY_STATUS'](state, data) {
        state.jobDisplayStatus = data;
    },
    ['SET_COMMENTS_HISTORY'](state, data) {
        state.comments = data;
    },
    ['RESET_COMMENTS_HISTORY'](state) {
        state.comments = []
    },
    ['SET_JOB_APPROVAL_PROCESS_COUNT'](state, data) {
        state.jobApprovalProcessCounts = data;
    },
    ['SET_JOB_CANDIDATE_PROCESS_COUNT'](state, data) {
        state.jobCandidateProcessCounts = data;
    },
    ['SET_INTERVIEW_SLOTS'](state, data) {
        state.interviewSlots = data;
    },
    ['RESET_INTERVIEW_SLOTS'](state) {
        state.interviewSlots = {};
    },
    ["SET_INTERVIEW_COMMENTS"](state, data) {
        state.interviewComments = data;
    },
    ["RESET_INTERVIEW_COMMENTS"](state) {
        state.interviewComments = null;
    },
    ["SET_ACTION_DETAIL"](state, data) {
        state.actionDetailByID = data;
    },
    ["SET_INTERVIEW_FEEDBACK"](state, data) {
        state.interviewFeedBack = data
    },
    ['SET_IS_FETCHING_ACTIVITY_TRANSACTIONS'](state, data) {
        state.isFetchingActivityTransactions = data;
    },
}

const actions = {
    fetchActivityList: ({ commit, dispatch, getters }, payload) => {
        const { getUserId: user_id, getActivityPaginationSkip: skip, getActivityPaginationLimit: limit, activityList } = getters
        commit("SET_IS_FETCHING_ACTIVITY_LIST", true);
        if (!payload?.pagination) {
            commit("SET_ACTIVITY_LIST_PAGINATION", {
                skip: 0,
                noMoreDataFromActivityList: false
            });
        }
        return activityService
            .activityList({ user_id, skip, limit })
            .then(res => {
                const { data } = res;
                if (data.length < limit) {
                    commit("SET_ACTIVITY_LIST_PAGINATION", {
                        noMoreDataFromActivityList: true
                    });
                } else {
                    commit("SET_ACTIVITY_LIST_PAGINATION", {
                        skip: skip + limit,
                        noMoreDataFromActivityList: false
                    });
                }
                if (payload?.pagination) {
                    commit("SET_ACTIVITY_LIST", activityList.concat(data));
                } else {
                    commit("SET_ACTIVITY_LIST", data);
                }
                return data
            })
            .catch(err => {
                console.log('Error While fetch activity list', err);
                return err;
            })
            .finally(res => {
                commit("SET_IS_FETCHING_ACTIVITY_LIST", false);
            })
    },
    fetchDocumentByCandidateID: ({ getters, commit, dispatch }, payload) => {
        commit("RESET_UPLOADED_DOCUMENT")
        const { candidate_uid } = payload;
        let query = ''
        return candidate
            .getUploadedDocument(candidate_uid, query)
            .then(async res => {
                commit("SET_UPLOADED_DOCUMENT", res.data);
            })
            .catch(err => {
                console.log("error fetching document type", err);
                return err;
            })
    },
    fetchActivity: ({ dispatch, commit }, payload) => {
        commit("RESET_SELECTED_ACTIVITY");
        dispatch("showLoader")
        const queryPayload = { action_detail_id: payload };
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return activityService
            .fetchActivity({ queryString })
            .then(res => {
                const { data } = res;
                const details = data && data.length && data[0] || null;
                commit("SET_SELECTED_ACTIVITY", details)
                return data;
            })
            .catch(err => {
                console.log("Error while Fetch Activity", err);
                return err;
            })
            .finally(res => {
                dispatch("hideLoader");
            })
    },
    fetchSelectedActivity: async ({ dispatch, getters, commit }, activity_id) => {
        const { activityList } = getters;
        let activityIndex;
        if (activityList && activityList.length) {
            activityIndex = activityList.findIndex(({ action_detail_id }) =>
                activity_id === action_detail_id
            );
        }
        if (activityIndex > -1) {
            const details = activityList[activityIndex];
            commit("SET_SELECTED_ACTIVITY", details)
            return details
        } else {
            return await dispatch("fetchActivity", activity_id);
        }
    },
    fetchSelectedJobActivity({ dispatch, commit }, payload) {
        commit("RESET_SELECTED_ACTIVITY");
        dispatch("showLoader")
        let queryPayload = { ...payload };
        queryPayload = { ...queryPayload, order_by: "-action_detail_id" }
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return activityService
            .jobCandidateTransaction({ queryString })
            .then(res => {
                const { data } = res;
                const details = data && data.length && data[0] || null;
                commit("SET_SELECTED_ACTIVITY", details)
            })
            .catch(err => {
                console.log("Error while Fetch Activity", err);
                return err;
            })
            .finally(res => {
                dispatch("hideLoader");
            })
    },
    fetchSelectedActivityComments: ({ dispatch, commit }, payload) => {
        commit("RESET_COMMENTS_HISTORY");
        dispatch("showLoader")
        let queryPayload = {};
        queryPayload = { ...queryPayload, ...payload };
        queryPayload = { ...queryPayload, order_by: '-created_on', all_rows: true }
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return activityService
            .fetchActivity({ queryString })
            .then(res => {
                const { data } = res;
                commit("SET_COMMENTS_HISTORY", data)
                return data;
            })
            .catch(err => {
                console.log("Error while Fetch Comments", err);
                return err;
            })
            .finally(res => {
                dispatch("hideLoader");
            })
    },
    interviewReviewer({ dispatch }, payload) {
        const { job_id, candidate_uid: candidate_id, action_detail_id, comments } = payload;
        let queryPayload = {};
        if (action_detail_id)
            queryPayload = { ...queryPayload, action_detail_id };
        if (comments)
            queryPayload = { ...queryPayload, comments };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        dispatch("showToast", { message: 'Updating...' });
        return activityService
            .interviewReviewer({ job_id, candidate_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully approved for Interview!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    holdReviewer({ dispatch }, payload) {
        const { job_id, candidate_uid: candidate_id, action_detail_id, comments } = payload;
        let queryPayload = {};
        if (action_detail_id)
            queryPayload = { ...queryPayload, action_detail_id };
        if (comments)
            queryPayload = { ...queryPayload, comments };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        dispatch("showToast", { message: 'Updating...' });
        return activityService
            .holdReviewer({ job_id, candidate_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate updated with Hold Status!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    disapproveReviewer({ dispatch }, payload) {
        const { job_id, candidate_uid: candidate_id, action_detail_id, comments } = payload;
        let queryPayload = {};
        if (action_detail_id)
            queryPayload = { ...queryPayload, action_detail_id };
        if (comments)
            queryPayload = { ...queryPayload, comments };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        dispatch("showToast", { message: 'Updating...' });
        return activityService
            .disapproveReviewer({ job_id, candidate_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Disapproved the candidate!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    queryReviewer({ dispatch }, payload) {
        const { job_id, candidate_uid: candidate_id, action_detail_id, comments } = payload;
        let queryPayload = {};
        if (action_detail_id)
            queryPayload = { ...queryPayload, action_detail_id };
        if (comments)
            queryPayload = { ...queryPayload, comments };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        dispatch("showToast", { message: 'Updating...' });
        return activityService
            .queryReviewer({ job_id, candidate_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Query sent to the initiator!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    approveAuthoriser({ dispatch }, payload) {
        const { job_id, customer_id, organisation_uid, action_detail_id, comment, status_id, status_name } = payload;
        dispatch("showToast", { message: 'Updating...' });
        const commentString = getFilterQueryStringWithoutArray(payload)
        return activityService
            .approveAuthoriser({ job_id, customer_id, organisation_uid, action_detail_id, payload: commentString, status_id })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: `${status_name} the Job!` })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    candidateInterviewSchedule({ commit, dispatch, getters }, payloadDate) {
        const { job_id, candidate_uid, payload } = payloadDate;
        const queryString = getFilterQueryStringWithoutArray(payload);
        return activityService.interviewSchedule({ job_id, candidate_uid, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Interview Scheduled' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Interview Schedule";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    interviewFeedBack({ dispatch, commit }, payload) {
        const { job_id, candidate_uid, action_detail_id, comments } = payload;
        let queryPayload = {};
        if (action_detail_id)
            queryPayload = { ...queryPayload, action_detail_id }
        if (comments)
            queryPayload = { ...queryPayload, comments }
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        return activityService.interviewFeedBack({ job_id, candidate_uid, queryString })
            .then(res => {
                const { data } = res;
                dispatch("showToast", { class: 'bg-success text-white', message: 'Comment updated successfully!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                let msg = 'Error While Update Interview Feedback';
                console.log(msg, err);
                dispatch("showToast", { class: 'bg-danget text-white', message: msg })
                return err;
            })
    },
    fetchAuthoriserActions({ dispatch, commit, getters }) {
        const { getCustomerId: customer_id } = getters
        return activityService.authoriserActions({ customer_id })
            .then(res => {
                const { data } = res;
                commit("SET_AUTHORISER_ACTIONS", data);
                return data;
            })
            .catch(err => {
                let msg = 'Error While Fetch Options';
                console.log(msg, err);
                dispatch("showToast", { class: 'bg-danget text-white', message: msg })
                return err;
            })
    },
    fetchActivityTransactions({ dispatch, commit, getters }, payload) {
        //Unique action by job_id, candidate_uid
        commit("SET_IS_FETCHING_ACTIVITY_TRANSACTIONS", true);
        dispatch("showLoader");
        const { getCustomerId: customer_uid, getActivityPaginationSkip: skip,
            getActivityPaginationLimit: limit, noMoreDataFromActivityList, getJobTransactions,
            getUserId: user_id
        } = getters
        if (!payload?.pagination) {
            commit("RESET_JOB_TRANSACTIONS")
            commit("SET_ACTIVITY_LIST_PAGINATION", {
                skip: 0,
                noMoreDataFromActivityList: false
            });
        }
        const { pagination, action_for, actionFrom, ...rest } = payload
        let queryPayload = {
            skip,
            limit,
            order_by: '-action_detail_id',
            ...rest
        }
        if (action_for == 'pending') {
            let assigned_to_payload = {};
            assigned_to_payload['assigned_to__contains'] = '{' + `${user_id}` + '}'
            queryPayload = { ...queryPayload, ...assigned_to_payload }
        }
        if (action_for == 'completed') {
            let completed_payload = {
                'initiated_by': user_id
            };
            queryPayload = { ...queryPayload, ...completed_payload }
        }
        if (customer_uid) {
            queryPayload = { ...queryPayload, customer_uid }
        }
        let queryString = getFilterQueryStringWithoutArray(queryPayload)
        return activityService.jobCandidateTransaction({ queryString })
            .then(res => {
                const { data } = res;
                if (data.length < limit) {
                    commit("SET_ACTIVITY_LIST_PAGINATION", {
                        noMoreDataFromActivityList: true
                    });
                } else {
                    commit("SET_ACTIVITY_LIST_PAGINATION", {
                        skip: skip + limit,
                        noMoreDataFromActivityList: false
                    });
                }
                if (payload?.pagination) {
                    commit("SET_JOB_TRANSACTIONS", getJobTransactions.concat(data));
                } else if (data.length) {
                    commit("SET_JOB_TRANSACTIONS", data);
                }
                return data;
            })
            .catch(err => {
                let msg = 'Error While Fetch Job Tractions';
                console.log(msg, err);
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            })
            .finally(() => {
                dispatch("hideLoader");
                commit("SET_IS_FETCHING_ACTIVITY_TRANSACTIONS", false);
            })
    },
    jobDisplayStatus({ dispatch, commit }, payload) {
        const queryString = getFilterQueryStringWithoutArray(payload)
        return activityService.jobDisplayStatus({ queryString })
            .then(res => {
                const { data } = res;
                commit("SET_JOB_DISPLAY_STATUS", data)
                return data;
            }).catch(err => {
                console.log("Error while fetch display status", err)
                return err
            })
    },
    resetJobTransactions({ commit }) {
        commit("SET_ACTIVITY_LIST_PAGINATION", {
            skip: 0,
            noMoreDataFromActivityList: false
        });
        commit("RESET_JOB_TRANSACTIONS")
        return
    },
    fetchAllPendingActivity({ commit, getters }, payload = {}) {
        const { to_date } = payload
        const { getCustomerId: customer_uid } = getters
        let queryPayload = {}
        queryPayload = {
            ...queryPayload,
            status_id__in: [28, 52, 54],
            sub_status_id__in: [72, 73, 53, 44, 51, 55],
            completed_by__in: 'null',
            customer_uid,
            date_field: 'initiated_date',
        }
        if (to_date) {
            queryPayload = { ...queryPayload, to_date }
        }
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        return activityService
            .jobCandidateTransaction({ queryString })
            .then(res => {
                const { data } = res;
                commit("SET_ACTIVITY_LIST", data);
                return data
            })
            .catch(err => {
                console.log("Error while fetch display status", err)
                return err
            })
    },
    transactionTabCounts({ commit, getters }, payload = {}) {
        const { action_id, assigned_to, return_action_to } = payload
        const { 
            getCustomerId: customer_uid,
            getUserId: user_id, 
            getOrgIDFromAccessToken: organisation_id, 
            isUKMainAgency 
        } = getters
        let queryPayload = { customer_uid };
        if(!isUKMainAgency){
            queryPayload = { ...queryPayload, organisation_id };
        }
        if (action_id) {
            queryPayload = { ...queryPayload, action_id }
        }
        if (assigned_to) {
            queryPayload = { ...queryPayload, ...{ assigned_to: user_id } }
        }
        if (return_action_to) {
            queryPayload = { ...queryPayload, ...{ return_action_to: user_id } }
        }
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        return activityService
            .transactionTabCounts({ queryString })
            .then(res => {
                const { data } = res;
                if (action_id == 19) {
                    commit("SET_JOB_APPROVAL_PROCESS_COUNT", data);
                }
                if (action_id == 18) {
                    commit("SET_JOB_CANDIDATE_PROCESS_COUNT", data);
                }
                return data
            })
            .catch(err => {
                console.log("Error while fetch display status", err)
                return err
            })
    },
    rejectInterviewCandidate({ commit, getters, dispatch }, payload) {
        const { job_id, candidate_uid: candidate_id, action_detail_id, comments } = payload;
        let queryPayload = {};
        if (action_detail_id)
            queryPayload = { ...queryPayload, action_detail_id };
        if (comments)
            queryPayload = { ...queryPayload, comments };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        dispatch("showToast", { message: 'Updating...' });
        return activityService
            .rejectInterviewCandidate({ job_id, candidate_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully Candidate Rejected!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    fetchActivityListActionDetail({ dispatch, commit, getters }, payload) {
        //Not unique action by job_id, candidate_uid
        commit("SET_IS_FETCHING_ACTIVITY_TRANSACTIONS", true);
        dispatch("showLoader");
        const { getCustomerId: customer_uid, getActivityPaginationSkip: skip,
            getActivityPaginationLimit: limit, noMoreDataFromActivityList, getJobTransactions,
            getUserId: user_id
        } = getters
        if (!payload?.pagination) {
            commit("RESET_JOB_TRANSACTIONS")
            commit("SET_ACTIVITY_LIST_PAGINATION", {
                skip: 0,
                noMoreDataFromActivityList: false
            });
        }
        const { pagination, action_for, actionFrom, ...rest } = payload
        let queryPayload = {
            skip,
            limit,
            order_by: '-action_detail_id',
            ...rest
        }
        if (action_for == 'pending') {
            let assigned_to_payload = {};
            assigned_to_payload['assigned_to__contains'] = '{' + `${user_id}` + '}'
            queryPayload = { ...queryPayload, ...assigned_to_payload }
        }
        if (action_for == 'completed') {
            let completed_payload = {
                'initiated_by': user_id
            };
            queryPayload = { ...queryPayload, ...completed_payload }
        }
        if (customer_uid) {
            queryPayload = { ...queryPayload, customer_uid }
        }
        let queryString = getFilterQueryStringWithoutArray(queryPayload)
        return activityService.fetchActivity({ queryString })
            .then(res => {
                const { data } = res;
                if (data.length < limit) {
                    commit("SET_ACTIVITY_LIST_PAGINATION", {
                        noMoreDataFromActivityList: true
                    });
                } else {
                    commit("SET_ACTIVITY_LIST_PAGINATION", {
                        skip: skip + limit,
                        noMoreDataFromActivityList: false
                    });
                }
                if (payload?.pagination) {
                    commit("SET_JOB_TRANSACTIONS", getJobTransactions.concat(data));
                } else if (data.length) {
                    commit("SET_JOB_TRANSACTIONS", data);
                }
                return data;
            })
            .catch(err => {
                let msg = 'Error While Fetch Job Tractions';
                console.log(msg, err);
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            })
            .finally(() => {
                dispatch("hideLoader");
                commit("SET_IS_FETCHING_ACTIVITY_TRANSACTIONS", false);
            })
    },
    fetchActionDetailByID({ commit, getters, dispatch }, { action_detail_id, payload }) {
        let queryPayload = {};
        if (payload)
            queryPayload = { ...queryPayload, ...payload }
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        return activityService
            .fetchActionDetailByID(action_detail_id, queryString)
            .then(res => {
                const { data } = res;
                commit("SET_ACTION_DETAIL", data);
                return res;
            })
            .catch(err => {
                const message = "Error while Fetch Action Detail";
                console.log(message, err);
                dispatch("showToast",{
                    class: "bg-danger text-white",
                    message
                })
                return err;
            })
    },
    createInterviewFeedBack({ commit, getters, dispatch }, payload) {
        const { completed } = payload
        return activityService
            .createInterviewFeedBack(payload)
            .then(res => {
                const message = completed ? "Interview Evaluation Form Submitted Successfully" : "Interview Evaluation Form Saved Successfully";
                dispatch("showToast",{
                    class: "bg-success text-white",
                    message
                })
                return res
            })
            .catch(err => {
                const message = "Error while adding Interview Evaluation Form"
                dispatch("showToast",{
                    class: "bg-danger text-white",
                    message
                })
                console.log(message, err);
                return err;
            })
    },
    fetchInterviewFeedBack({ commit, getters, dispatch }, payload = {}) {
        const queryString = getFilterQueryStringWithoutArray(payload);
        return activityService
            .fetchInterviewFeedBack(queryString)
            .then(res => {
                const { data } = res;
                commit("SET_INTERVIEW_FEEDBACK", data);
                return res;
            })
            .catch(err => {
                const message = "Error while fetch interview feedback";
                console.log(message, err);
                dispatch("showToast",{
                    class: "bg-danger text-white",
                    message
                })
                return err;
            })
    },
    updateInterviewFeedBack({commit,getters,dispatch},data){
        const {payload:{completed}}=data;
        return activityService
        .updateInterviewFeedBack(data)
        .then(res=>{
            const message = completed ? "Interview Evaluation Form Submitted Successfully" : "Interview Evaluation Form Updated Successfully";
                dispatch("showToast",{
                    class: "bg-success text-white",
                    message
                })
            return res;
        })
        .catch(err=>{
            const message = "Error while Update interview feedback";
                console.log(message, err);
                dispatch("showToast",{
                    class: "bg-danger text-white",
                    message
                })
            return err;
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
};